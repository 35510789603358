import { useState } from 'react';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Button, Box } from '@mui/material';
import {
  Notifications,
  OtherIntegrations,
  PaymentAccountIntegration,
} from '../../components';

import styles from './style.module.scss';
import { BreadcumbItem } from 'components/ui';
import Platforms from 'components/integrations/platforms';
import CustomerCommunications from 'components/integrations/customerCommunication';
import Subscriptions from 'components/integrations/subscriptions';

const Integrations = () => {
  const navigate = useNavigate();
  const [clickedTab, setClickedTab] = useState<string>('paymentAccount');

  function handleBreadcrumb(page: string) {
    navigate(page);
  }

  return (
    <div className={styles.disputesWrapper}>
      <Notifications.Activate />
      <div className={styles.nav}>
        <h3>Integrations</h3>
        <div className={styles.navPath}>
          <BreadcumbItem
            title={'Home'}
            onClick={() => handleBreadcrumb('/home')}
            className={styles.cursor}
          />
          <BreadcumbItem title={'Integrations'} className={styles.cursor} />
          <BreadcumbItem
            title={
              clickedTab === 'paymentAccount'
                ? 'Payment Account (PSP)'
                : 'Other Integrations'
            }
            className={styles.cursor}
            off
          />
        </div>
      </div>

      <Box className={styles.boxWrapper} sx={{ width: '100%' }}>
        <Box className={styles.tabsWrapper}>
          <div className={styles.integrationTabs}>
            <Button
              className={
                clickedTab === 'paymentAccount'
                  ? cn(styles.integrationTab, styles.activeTab)
                  : styles.integrationTab
              }
              onClick={() => setClickedTab('paymentAccount')}
            >
              <p>Payment Account (PSP)</p>
            </Button>
            <Button
              className={
                clickedTab === 'platforms'
                  ? cn(styles.integrationTab, styles.activeTab)
                  : styles.integrationTab
              }
              onClick={() => setClickedTab('platforms')}
            >
              <p>Platforms</p>
            </Button>
            <Button
              className={
                clickedTab === 'subscriptions'
                  ? cn(styles.integrationTab, styles.activeTab)
                  : styles.integrationTab
              }
              onClick={() => setClickedTab('subscriptions')}
            >
              <p>Subscriptions</p>
            </Button>

            <Button
              className={
                clickedTab === 'customerCommunication'
                  ? cn(styles.integrationTab, styles.activeTab)
                  : styles.integrationTab
              }
              onClick={() => setClickedTab('customerCommunication')}
            >
              <p>Customer Communication</p>
            </Button>
          </div>
        </Box>
        {clickedTab === 'paymentAccount' ? (
          <div className={styles.customWrapper}>
            <PaymentAccountIntegration />
          </div>
        ) : clickedTab === 'otherIntegrations' ? (
          <div className={styles.customWrapper}>
            <OtherIntegrations />
          </div>
        ) : clickedTab === 'platforms' ? (
          <div className={styles.customWrapper}>
            <Platforms />
          </div>
        ) : clickedTab === 'subscriptions' ? (
          <div className={styles.customWrapper}>
            <Subscriptions/>
          </div>
        ) : clickedTab === 'customerCommunication' ? (
          <div className={styles.customWrapper}>
            <CustomerCommunications/>
          </div>
        ) : (
          ''
        )}
      </Box>
    </div>
  );
};

export default Integrations;
