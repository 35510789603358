import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../loading/loading";
import { postKlarnaSettingsRequest } from "redux/actions";
import styles from "./style.module.scss";

interface klarnaProps {
  handleNewIntegration: () => void;
}

const dataCollector = [
  // {
  //   id: 0,
  //   title: "Merchant ID",
  //   placeholder: "Type Merchant ID...",
  //   registerName: "merchantId",
  //   error: "Please fill the valid Merchant ID",
  // },
  {
    id: 1,
    title: "Username",
    placeholder: "Type Username...",
    registerName: "userName",
    error: "Please fill the valid Username",
  },
  {
    id: 2,
    title: "Password",
    placeholder: "Type Password...",
    registerName: "password",
    error: "Please fill the valid Password",
  },
];

const KlarnaIntegration = ({ handleNewIntegration }: klarnaProps) => {
  const dispatch = useDispatch();
  const [isKlarnaCheck, setIsKlarnaCheck] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  const { isLoading, isSuccess, isError } = useSelector(
    (state: any) => state?.klarnaStore?.klarnaSettings
  );

  const onSubmit = (data: any) => {
    setIsKlarnaCheck(true);
    reset();
    dispatch(postKlarnaSettingsRequest(data));
  };

  useEffect(() => {
    if (isKlarnaCheck) {
      if (isSuccess) {
        toast.success("Klarna connect successfully", {
          theme: "colored",
        });
        setIsKlarnaCheck(false);
        setTimeout(() => handleNewIntegration(), 3000);
      } else if (isError) {
        toast.error("Oops! Klarna connect fail. Please try again", {
          theme: "colored",
        });
        setIsKlarnaCheck(false);
      }
    }
  }, [isSuccess, isError]);

  return (
    <div className={styles.klarnaWrapper}>
      <h3 className={styles.klarnaHeading}>Klarna</h3>
      <div className={styles.klarnaDescri}>
        <p style={{padding:0, margin:0}}>How to connect?</p>
        <p>
          To connect your Klarna account to <span>ChargePay</span>, please follow the steps
          below:
        </p>
        <div className={styles.klarnaSteps}>
          <ol>
            <li>Login to <span>Klarna Dashboard</span></li>
            <li>Go to <span>Settings</span> {">"} <span>Klarna API Credentials</span></li>
            <li>

              Click on <span>“Generate new Klarna API credentials”</span> then <span>“Create Credentials”</span>
            </li>
            <li>
            Insert the credentials according to the relevant fields below and clck <span>“Connect”</span>.
            </li>
          </ol>
        </div>
        {isLoading ? (
          <Loading />
        ) : (
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.form}>
              {dataCollector?.map((data) => {
                return (
                  <div key={data?.id} className={styles.returnShippingAddress}>
                    <label>{data?.title}</label>
                    <input
                      type={
                        data.registerName === "password" ? "password" : "text"
                      }
                      autoComplete='off'
                      placeholder={data?.placeholder}
                      {...register(data?.registerName, {
                        validate: (value) => value.length > 0,
                      })}
                    />
                    {errors[data?.registerName] && (
                      <p className={styles.error}>{data?.error}</p>
                    )}
                  </div>
                );
              })}
            </div>
            <Button variant="contained" type="submit">
              Connect
            </Button>
          </form>
        )}
      </div>
    </div>
  );
};

export default KlarnaIntegration;
