// import { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Button } from "@mui/material";
// import { svgIcons } from "elements";
// import {
//   AddAccount,
//   NoDataFound,
//   Loading,
//   BrainTreeIntegration,
//   Pagination,
// } from "components";
// import {
//   deletePayPalRequest,
//   getListPspListRequest,
//   postConnectPayPalRequest,
// } from "redux/actions";

// import styles from "./style.module.scss";
// import KlarnaIntegration from "components/klarna";

// const PaymentAccountIntegration = () => {
//   const dispatch = useDispatch();
//   const [page, setPage] = useState(1);
//   const [isAddAccountOpen, setIsAddAccountOpen] = useState<boolean>(false);
//   const [isBrainTreeForm, setBrainTreeForm] = useState<boolean>(false);
//   const [isKlarnaForm, setisKlarnaForm] = useState<boolean>(false);
//   const tableList = 7;

//   const code = new URLSearchParams(window.location.search).get("code");

//   const { isLoading, isSuccess, isError, data } = useSelector(
//     (state: any) => state?.listPSP?.listPspList
//   );
//   const connectPaypal = useSelector(
//     (state: any) => state?.connectPayPalStore?.connectPaypal
//   );
//   const deletePayPal = useSelector(
//     (state: any) => state?.connectPayPalStore?.deletePayPal
//   );

//   const onAddAccountClick = () => {
//     setIsAddAccountOpen(!isAddAccountOpen);
//     setBrainTreeForm(false);
//     setisKlarnaForm(false);
//   };
//   const handlePaypalRemove = (id: number) => {
//     dispatch(deletePayPalRequest(id));
//   };

//   const handleNewIntegration = () => {
//     setBrainTreeForm(false);
//     setIsAddAccountOpen(false);
//     setisKlarnaForm(false);
//     dispatch(getListPspListRequest());
//   };

//   const onPageChange = (pageNumber: number) => {
//     setPage(pageNumber);
//   };

//   useEffect(() => {
//     if (code) {
//       const payload = {
//         code,
//       };
//       dispatch(postConnectPayPalRequest(payload));
//     }
//   }, [code, dispatch]);

//   useEffect(() => {
//     // const payload = {
//     //     page: page,
//     //     pageLength: tableList
//     // }
//     // dispatch(getConnectedPayPalListRequest(payload))
//     dispatch(getListPspListRequest());
//   }, [
//     connectPaypal?.data,
//     deletePayPal?.isSuccess,
//     deletePayPal?.data,
//     dispatch,
//   ]);

//   return (
//     <div className={styles.integrationMainWrapper}>
//       <div className={styles.integrationMain}>
//         <div className={styles.integrationHeader}>
//           <h3>Payment Account (PSP)</h3>
//           <Button onClick={onAddAccountClick}>
//             {isAddAccountOpen ? (
//               <p>Cancel</p>
//             ) : (
//               <>
//                 <img src={svgIcons.Plus_Circle} alt="plus" />
//                 <p>Add new PSP</p>
//               </>
//             )}
//           </Button>
//         </div>
//         {isBrainTreeForm ? (
//           <div className={styles.addAccount}>
//             <BrainTreeIntegration handleNewIntegration={handleNewIntegration} />
//           </div>
//         ) : isKlarnaForm ? (
//           <div className={styles.addAccount}>
//             <KlarnaIntegration handleNewIntegration={handleNewIntegration} />
//           </div>
//         ) : (
//           <>
//             {isAddAccountOpen ? (
//               <div className={styles.addAccount}>
//                 <AddAccount
//                   name="Add new account"
//                   setBrainTreeForm={setBrainTreeForm}
//                   setisKlarnaForm={setisKlarnaForm}
//                 />
//               </div>
//             ) : (
//               <div className={styles.integrationDataWrapper}>
//                 {isLoading && <Loading />}
//                 {(!isLoading && isError) ||
//                 (isSuccess && data?.length === 0) ||
//                 (!isLoading && !data?.length) ? (
//                   <NoDataFound
//                     icon={svgIcons?.UnderReview}
//                     isError={false}
//                     errorStatement="Oops! Something went wrong. Please try again."
//                     notFoundMessage="No Payment Account (PSP) found"
//                   />
//                 ) : (
//                   <>
//                     {isSuccess &&
//                       data?.length > 0 &&
//                       data?.map((payment: any) => {
//                         return (
//                           <div
//                             key={payment?.id}
//                             className={styles.integratedData}
//                           >
//                             <div className={styles.integratedInfo}>
//                               <div className={styles.icon}>
//                                 {payment.paymentProcessor === "PayPal" && (
//                                   <img src={svgIcons.Paypal} alt="icon" />
//                                 )}
//                                 {payment.paymentProcessor === "Klarna" && (
//                                   <img src={svgIcons.klarna} alt="icon" />
//                                 )}
//                                 {payment.paymentProcessor === "stripe" && (
//                                   <img src={svgIcons.Stripe} alt="icon" />
//                                 )}
//                                 {payment.paymentProcessor === "BrainTree" && (
//                                   <img src={svgIcons.BrainTree} alt="icon" />
//                                 )}
//                               </div>
//                               <div>
//                                 <h4>{payment?.description}</h4>
//                                 <p>{payment?.payerEmail}</p>
//                                 <p className={styles.id}>
//                                   {payment?.payerId ?? payment?.merchantId}
//                                 </p>
//                               </div>
//                             </div>
//                             <Button
//                               onClick={() => handlePaypalRemove(payment?.id)}
//                             >
//                               <img src={svgIcons.Trash_1} alt="trash" />
//                             </Button>
//                           </div>
//                         );
//                       })}
//                     {Math.ceil(data?.length / tableList) > 1 && (
//                       <Pagination
//                         onPageChange={onPageChange}
//                         page={page}
//                         totalPages={Math.ceil(data?.length / tableList)}
//                       />
//                     )}
//                   </>
//                 )}
//               </div>
//             )}
//           </>
//         )}
//       </div>
//     </div>
//   );
// };
// export default PaymentAccountIntegration;

import { svgIcons } from 'elements';
import { useState } from 'react';
import styles from './style.module.scss';
import {
  // generateBrainTreeAuthorizeUrl,
  generatePayPalAuthorizeUrl,
  generateStripeAuthorizeUrl,
} from 'utils/GenerateStoreUrls';
import { useSelector } from 'react-redux';
import {
  Box,
  Modal,
} from '@mui/material';
import BrainTreeIntegration from 'components/brainTree';
import { useDispatch } from 'react-redux';
import { getListPspListRequest } from 'redux/actions';
import KlarnaIntegration from 'components/klarna';
import { ToastContainer } from 'react-toastify';
import Login from 'components/login/Login';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const cardsData = [
  {
    id: '1',
    cardImg: 'shopifyPayment',
    title: 'Shopify Payments',
    desc: 'lorem ipsum dolor sit amet connector innit',
    connect: false,
  },
  {
    id: '2',
    cardImg: 'Klarna',
    title: 'Klarna',
    desc: 'lorem ipsum dolor sit amet connector innit',
    connect: false,
  },
  {
    id: '3',
    cardImg: 'PayPal',
    title: 'PayPal',
    desc: 'lorem ipsum dolor sit amet connector innit',
    connect: false,
  },
  {
    id: '4',
    cardImg: 'stripe',
    title: 'Stripe',
    desc: 'lorem ipsum dolor sit amet connector innit',
    connect: false,
  },
  {
    id: '5',
    cardImg: 'BrainTree',
    title: 'BrainTree',
    desc: 'lorem ipsum dolor sit amet connector innit',
    connect: false,
  },
];

const PaymentAccountIntegration = () => {
  const [cards, setCards] = useState(cardsData);
  const dispatch = useDispatch();
  const [userDomain, setUserDomain] = useState('');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { data: stripeData } = useSelector(
    (state: any) => state?.stripe?.stripeLogin
  );
  const [isBrainTreeForm, setBrainTreeForm] = useState<boolean>(false);
  const [isKlarnaForm, setisKlarnaForm] = useState<boolean>(false);
  const [isShopifyForm, setIsShopifyForm] = useState<boolean>(false);

  const handleNewIntegration = () => {
    setBrainTreeForm(false);
    setisKlarnaForm(false);
    dispatch(getListPspListRequest());
  };

  const handleConnect = (store: string) => {
    let redirectUrl = '';
    setBrainTreeForm(false);
    setIsShopifyForm(false);
    setisKlarnaForm(false);
    switch (store) {
      case 'PayPal':
        redirectUrl = generatePayPalAuthorizeUrl();
        break;
      case 'Stripe':
        redirectUrl = generateStripeAuthorizeUrl(stripeData?.data?.id);
        break;
      case 'BrainTree':
        // redirectUrl = generateBrainTreeAuthorizeUrl();
        setBrainTreeForm(true);
        break;
      case 'Klarna':
        setisKlarnaForm(true);
        break;

      case 'Shopify Payments':
        setIsShopifyForm(true);
        break;
    }
    if (store === 'PayPal' || store === 'Stripe') {
      handleClose();
      window.location.replace(redirectUrl);
    } else {
      handleOpen();
    }
    // store === 'PayPal' ||
    //   (store === 'Stripe' && window.location.replace(redirectUrl));
    // handleOpen();

    // setCards(prevCard =>
    //   prevCard.map(card => (card.id === id ? { ...card, connect: true } : card))
    // );
  };

  return (
    <div className={styles.cardsContainer}>
      {cards.map(card => (
        <div key={card.id} className={styles.cardData}>
          <div className={styles.cardImg}>
            <img
              src={
                card.cardImg === 'shopifyPayment'
                  ? svgIcons.ShopifyIcon
                  : card.cardImg === 'Klarna'
                  ? svgIcons.klarna
                  : card.cardImg === 'PayPal'
                  ? svgIcons.PaypalIcon
                  : card.cardImg === 'stripe'
                  ? svgIcons.Stripe
                  : card.cardImg === 'BrainTree'
                  ? svgIcons.BrainTree
                  : svgIcons.Star_Blue
              }
              alt='icon'
            />
          </div>
          <div className={styles.cardText}>
            <p className={styles.cardTitle}>{card.title}</p>
            <p>{card.desc}</p>
            <button
              className={styles.connectBtn}
              style={{
                border: `${
                  card.connect ? '1px solid #20d120' : '1px solid #ddd '
                }`,
                color: card.connect ? '#20d120' : 'gray',
                background: `${card.connect} ? "#edf0ed" : ""`,
              }}
              onClick={() => handleConnect(card.title)}
            >
              {card.connect ? 'Connected' : 'Connect'}
            </button>
          </div>
        </div>
      ))}
      {isBrainTreeForm && (
        <>
          <ToastContainer />
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box sx={style}>
              <BrainTreeIntegration
                handleNewIntegration={handleNewIntegration}
              />
            </Box>
          </Modal>
        </>
      )}

      {isKlarnaForm && (
        <>
          <ToastContainer />
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box sx={style}>
              <KlarnaIntegration handleNewIntegration={handleNewIntegration} />
            </Box>
          </Modal>
        </>
      )}
      {isShopifyForm && (
        <>
          <ToastContainer />
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box sx={style}>
              <div>
                <Login />
              </div>
            </Box>
          </Modal>
        </>
      )}
    </div>
  );
};

export default PaymentAccountIntegration;
